<template>
  <!-- 发送列表 -->
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div class="append">
      <div class="seach">
        <el-select clearable v-model="pageInfo.profileId" placeholder="全部店铺" class="el_select">
          <el-option-group 
            :key="group.id"
            :label="group.name"
            v-for="group in shopList"
          >
            <el-option 
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name+'-'+item.name"
              :value="item.profileId"
              @click.native="checkGroup(group.id, item.id)"
            >{{ item.name }}
            </el-option>
          </el-option-group>
        </el-select>
        <el-select clearable v-model="pageInfo.stateType" placeholder="发送状态" class="el_select">
          <el-option
            v-for="item in stateTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.emailType" placeholder="请选择邮件类型" class="el_select">
          <el-option 
           v-for="item in mailTypeList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
          ></el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.operatorUser" placeholder="全部操作人" class="el_select">
          <el-option
           v-for="item in operateUserList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
          ></el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.timeType" placeholder="选择时间类型" class="el_select">
          <el-option 
           v-for="item in timeTypeList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
          ></el-option>
        </el-select>
        <TimeQuantum
          style="margin-right:10px" 
          @selectFinish='topTime'
          section="近7天"
          @sectionFinish='sectionSure'
        ></TimeQuantum>
        <el-input style="width:360px;" v-model="keyword" placeholder="请选择">
          <el-select 
            clearable
            @clear="clearAsin"
            slot="prepend"
            v-model="selectContent" 
            placeholder="请选择" 
            style="width: 120px"
            >
            <el-option
             v-for="item in selectList"
             :key="item.id"
             :label="item.value"
             :value="item.id">
            </el-option>
        </el-select>
        </el-input>
        <el-button icon="el-icon-refresh" @click="reset" style="margin-left: 15px">重置</el-button>
        <el-button type="primary" icon="el-icon-search" @click="Search(shopId, marketplaceId)">查询</el-button>
      </div>
      <div class="butGroupClass">
        <el-button type="primary" @click="handleRejecte">加入拒收名单</el-button>
        <el-button @click="handleCancel">取消发送</el-button>
        <el-button @click="handleRenew">重新发送</el-button>
      </div>
      <!-- 表格 -->
      <el-table
        border
        :data="tableList"
        cell-style="text-align:center"
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        style="width: 100%; margin-top: 30px"
        @selection-change="handleSelectionChange"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="邮箱账号" prop="buyerEmail" align="center"></el-table-column>
        <el-table-column label="买家姓名" prop="buyerName" align="center"></el-table-column>
        <el-table-column label="相关订单" prop="orderNumber" align="center"></el-table-column>
        <el-table-column label="模板名称" prop="modelName" align="center"></el-table-column>
        <el-table-column label="国家" prop="country" align="center"></el-table-column>
        <el-table-column label="邮件类型" prop="type" align="center"></el-table-column>
        <el-table-column label="店铺" prop="shopName" align="center"></el-table-column>
        <el-table-column prop="statusName" align="center">
          <template slot="header">
            <span>发送状态</span>
            <el-tooltip effect="dark" placement="top" content="待发送状态的邮件需要通过点击刷新查看最新进度">
             <span class="el-icon-question"></span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.status == '0'" style="color: #909399">待发送</span>
            <span v-if="scope.row.status == '1'" style="color: #228B22">已发送</span>
            <span v-if="scope.row.status == '2'" style="color: #FF0000">发送失败</span>
            <span v-if="scope.row.status == '3'" style="color: #E6A23C">已拒收</span>
            <span v-if="scope.row.status == '4'" style="color: #F56C6C">已取消</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="updateBy" align="center"></el-table-column>
        <el-table-column label="订购时间" prop="purchaseDate" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="发货时间" prop="sendDate" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="退货时间" prop="" align="center"></el-table-column>
        <el-table-column label="邮件发送时间" prop="" align="center"></el-table-column>
        <el-table-column prop="prop" label="操作" align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[20, 30, 50, 100]"
        :total="total"
        :page-size="pageInfo.pageSize"
        :current-page="pageInfo.current"
        style="text-align: center; margin-top: 15px"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum'
import {
  pageInit,
  listPage,  // 查询分页
  batchUpdate,  // 改变状态
  getCareMailAllOperateMan, 
}from '@/api/Mail/sendList.js'
export default {
  components: {
    TimeQuantum,
  },
  data() {
    return {
      total: 0,
      loading: false,
      pictLoading: false,
      shopList: [],     // 店铺集合
      tableList: [],
      keyword: "",  // 关键词
      selectContent: "",
      shopId: "",
      marketplaceId: "",
      multipleSelection: [],
      pageInfo: {
        profileId: "",
        stateType: "",  // 发送状态
        emailType: "",  // 邮件类型
        operatorUser: "",
        timeType: "",   // 时间类型
        startTime: "",  // 开始时间
        endTime: "",    // 结束时间
        current: 1,
        pageSize: 10,
      },
      stateTypeList: [],
      selectList: [],
      mailTypeList: [],
      operateUserList: [],
      timeTypeList: [],
    };
  },
  watch: {},
  created() {
    this.getInitList();
  },
  mounted() {},
  methods: {
    // 初始化数据
    getInitList() {
      pageInit()
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.stateTypeList = res.data.data.sendStatus;
           this.mailTypeList = res.data.data.mailType;  // 邮件类型
           this.operateUserList = res.data.data.operateManList;  // 全部操作人
           this.timeTypeList = res.data.data.timeType;  // 时间类型
           this.selectList = res.data.data.searchType;  // 查询列表
           this.shopList = res.data.data.shopList;
           res.data.data.shopList.forEach(item => {
             item.marketPlaceTree.forEach(data=> {
               if(data.isSelected == 1) {
                 this.shopId = item.id;
                 this.marketplaceId = data.id;
                 this.pageInfo.profileId = data.profileId;
                 this.Search(item.id, data.id);
               }
             })
           });
         }
       })
    },
    // 重置功能
    reset() {
      this.pageInfo.profileId = "",
      this.pageInfo.stateType = "";
      this.pageInfo.emailType = "";
      this.pageInfo.operatorUser = "";
      this.pageInfo.timeType = "";
      this.keyword = "";
      this.selectContent = "";
    },
    checkGroup(parentId, subId) {
      this.shopId = parentId;
      this.marketplaceId = subId;
      this.pageInfo.operatorUser = "";
      let params = {
        shopId: parentId,
        marketPlaceId: subId
      }
      getCareMailAllOperateMan(params)
       .then(res=> {
         this.operateUserList = [];
         if(res.data.code == 500) {
          this.$message.error(res.data.message);
         }else {
          this.operateUserList = res.data.data;
         }
       })
    },
    topTime(val) {
      this.pageInfo.startTime = val.value[0];
      this.pageInfo.endTime = val.value[1];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查询按钮
    Search(shopId, marketplaceId) {
      this.pictLoading = true;
      let params = {
        current: this.pageInfo.current,
        pageSize: this.pageInfo.pageSize,
        shopId: shopId,
        marketPlaceId: marketplaceId,
        status: this.pageInfo.stateType,
        type: this.pageInfo.emailType,
        updateBy: this.pageInfo.operatorUser,
        timeType: this.pageInfo.timeType,
        beginTime: this.pageInfo.startTime,
        endTime: this.pageInfo.endTime,
        searchType: this.selectContent,
        searchContent: this.keyword
      }
      listPage(params)
       .then(res=> {
         this.pictLoading = false;
         this.tableList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.tableList = res.data.values;
           this.total = res.data.pageInfo.total;
         }
       })
    },
    // 拒收名单
    handleRejecte() {
      if(!this.multipleSelection.length) {
        this.$message.error('请勾选要操作的邮箱账号');
        return false;
      } 
      this.handleStateMethod("addRefuse");
    },
    // 取消发送
    handleCancel() {
      if(!this.multipleSelection.length) {
        this.$message.error('请勾选要取消发送的消息');
        return false;
      }
      let idList = this.multipleSelection.map(v => v.status);
      const test = (ele) => {
        return ele == 0;
      };
      let flag = idList.every(test);
      if(!flag) {
        this.$message.error('只有待发送状态的数据才可以取消发送');
        return false;
      }
      this.handleStateMethod("cancel");
    },
    // 重新发送
    handleRenew() {
      if(!this.multipleSelection.length) {
        this.$message.error('请勾选要操作的任务');
        return false;
      }
      this.handleStateMethod("resend");
    },
    handleStateMethod(type) {
      let ids = [];
      this.multipleSelection.forEach(data=> {
        ids.push(data.id)
      })
      let data = {
        type: type,
        ids: ids
      }
      if(type == "addRefuse") {   // 加入拒收
        this.$confirm(`共${this.multipleSelection.length}个数据, 确定加入拒收名单?`, '加入拒收名单', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
        }).then(() => {
          batchUpdate(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.Search(this.shopId, this.marketplaceId)
            }
          })
         }).catch(() => {});
      }else if(type == "cancel") {
        this.$confirm(`共${this.multipleSelection.length}个数据, 确定取消发送?`, '取消发送', {
             confirmButtonText: '确定',
             cancelButtonText: '取消',
             type: 'warning'
        }).then(() => {
         batchUpdate(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.Search(this.shopId, this.marketplaceId)
           }
         })
        }).catch(() => {});
      }else if(type == "resend") {
        this.$confirm(`共${this.multipleSelection.length}个数据, 确定重新发送?`, '重新发送', {
             confirmButtonText: '确定',
             cancelButtonText: '取消',
             type: 'warning'
        }).then(() => {
         batchUpdate(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.Search(this.shopId, this.marketplaceId)
           }
         })
        }).catch(() => {});
      }
    },
    handleSizeChange(newSize) {
      this.pageInfo.pageSize = newSize;
      this.Search(this.shopId, this.marketplaceId)
    },
    handleCurrentChange(newCurrent) {
      this.pageInfo.current = newCurrent;
      this.Search(this.shopId, this.marketplaceId)
    }
  },  
};
</script>

<style scoped lang="scss">
.append {
  .seach {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .el-table {
      margin-top: 25px;
      margin-bottom: 50px;
    }
    .el_select {
      margin-right: 20px;
    }
    .el-pagination {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
.butGroupClass {
  margin-top: 20px;
}
</style>