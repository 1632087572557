import request from '@/utils/request'

// 发送列表页面
// 查询初始化
export function pageInit() {
  return request({
    url: "/api/amzCaremailRuleRecord/pageInit",
    method: "get"
  })
}

// 查询分页
export function listPage(params) {
  return request({
     url: "/api/amzCaremailRuleRecord/listPage",
     params,
     method: "get" 
  })
}

// 批量操作
export function batchUpdate(data) {
  return request({
     url: "/api/amzCaremailRuleRecord/batchUpdate",
     data,
     method: "put" 
  })
}

// 获取操作人数据
export function getCareMailAllOperateMan(params) {
   return request({
       url: "/api/amzCaremailRuleRecord/getCareMailAllOperateMan",
       params,
       method: "get"
   })
}